<template>
  <div>&nbsp;</div>
</template>
<script>
import {isInWeixin} from "@/utils/helper";
import {signatureConfig} from '@/api/wechat'
import wx from "weixin-js-sdk";
export default {
  name: "Wechat",
  props:{
    infoOk:{
      type: Boolean,
      default: false
    },
    title:{
      type: String,
      default: ''
    },
    link:{
      type: String,
      default: ''
    },
    imgUrl:{
      type: String,
      default: ''
    },
    desc:{
      type: String,
      default: ''
    },
  },
  data(){
    return{
      wxReady: false
    }
  },
  mounted() {
    if(isInWeixin()){
      this.initWechat()
    }
  },

  watch:{
    infoOk(val){
      val && this.initShare()
    },
  },

  methods:{
    initShare(){},
    initWechat(){
      const href = window.location.href
      signatureConfig(href).then(res=>{
        if(res.code === 200){
          const { appId, nonceStr, signature, timestamp } = res.data
          const weChatConfig = {
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature,// 必填，签名
            jsApiList: [
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'hideAllNonBaseMenuItem',
              'showMenuItems',
              'chooseWXPay'
            ] // 必填，需要使用的JS接口列表
          }
          wx.config(weChatConfig)
          wx.ready(()=>{
            console.log('ready')
            this.wxReady = true
            this.initWechatMenus()
            this.initShare()
          })
        }
      }, err=>{
        console.log(err)
      })

    },
    couldShare(){
      const canShareRoute = ['product', 'orderConfirm']
      return canShareRoute.indexOf(this.$route.name)>-1
    },
    initWechatMenus(){
      if(!this.couldShare()){
        wx.hideAllNonBaseMenuItem();
      }
      //wx.hideAllNonBaseMenuItem();
      //this.couldShare()
    },
  }
}
</script>

<style scoped>

</style>
